// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const ALBUM_HAS_BEEN_DELETED = "Album bol zmazan\u00fd.";
export const ARE_YOU_SURE_YOU_WANT_TO_DELETE_ALBUM = "Naozaj chce\u0161 zmaza\u0165 album?";
export const ARE_YOU_SURE_YOU_WANT_TO_DELETE_ARTICLE = "Naozaj chce\u0161 zmaza\u0165 \u010dl\u00e1nok?";
export const ARE_YOU_SURE_YOU_WANT_TO_DELETE_SHARE = "Naozaj chce\u0161 zmaza\u0165 zdie\u013ean\u00fd pr\u00edspevok?";
export const ARE_YOU_SURE_YOU_WANT_TO_DELETE_SHORT_MESSAGE = "Naozaj chce\u0161 zmaza\u0165 kr\u00e1tku spr\u00e1vu?";
export const ARTICLE_HAS_BEEN_DELETED = "\u010cl\u00e1nok bol zmazan\u00fd.";
export const BLOGS_IGNORE_POSTS_FROM_USER_username_link_GROWL = "Pr\u00edspevky od %(username)s sa nebud\u00fa zobrazova\u0165. %(link)s.";
export const EXPLAIN_POST_DELETION = "Zadaj bli\u017e\u0161ie od\u00f4vodnenie:";
export const LIST_OF_IGNORED_USERS = "Zoznam ignorovan\u00fdch pou\u017e\u00edvate\u013eov";
export const SHARE_HAS_BEEN_DELETED = "Zdie\u013ean\u00fd pr\u00edspevok bol zmazan\u00fd.";
export const SHORT_MESSAGE_HAS_BEEN_DELETED = "Kr\u00e1tka spr\u00e1va bola zmazan\u00e1.";
export const THE_POST_WAS_REMOVED_FROM_FEED = "Pr\u00edspevok bol odobran\u00fd z feedu.";
export const THE_POST_WAS_RETURNED_TO_FEED = "Pr\u00edspevok bol vr\u00e1ten\u00fd do feedu.";
