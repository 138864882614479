import { url, ID_RE } from 'mk2/helpers/urls';

const ARTICLE_ID = `:articleId(${ID_RE})`;
const PRODUCT_SEGMENT = `:productSegment(strollers|carseats)`;
const PATTERN_ID = `:patternId(${ID_RE})`;
const PROMPT_ID = `:promptId(${ID_RE})`;


export const adminclubUrl = `/adminclub/`;
export const adminclubSeoForumUrl = `/adminclub/forum/seo/`;
export const adminclubTaggingForumUrl = `/adminclub/tagging/forum`;
export const adminclubConceptsHierarchyUrl = `/adminclub/concepts-hierarchy/`;
export const adminclubXMLImportFeedsUrl = `/adminclub/xml-import/feeds/`;
export const adminclubXMLImportStatsUrl = `/adminclub/xml-import/stats/`;
export const adminclubXMLImportItemsUrl = `/adminclub/xml-import/items/`;
export const adminclubXMLImportNewFeedUrl = `/adminclub/xml-import/feeds/add/`;
export const adminclubXMLImportEditFeedUrl = `/adminclub/xml-import/feeds/:feedId(${ID_RE})/edit/`;
export const adminclubUsers = `/adminclub/users/`;
export const adminclubTickets = `/adminclub/tickets/`;
export const adminclubArticlesAndVendors = `/adminclub/wiki/articles-vendors/`;
export const adminclubReviewCategoryCreate = `/adminclub/wiki/review-category/create/`;
export const adminclubReviewCategoryEdit = `/adminclub/wiki/review-category/edit/${ARTICLE_ID}`;
export const adminclubProductCatalogsUrl = `/adminclub/product-catalog/`;
export const adminclubProductCatalogImportUrl = `/adminclub/product-catalog/${PRODUCT_SEGMENT}/import/`;
export const adminclubProductCatalogImportStrollersUrl = url(`/adminclub/product-catalog/${PRODUCT_SEGMENT}/import/`, {productSegment: 'strollers'});
export const adminclubProductCatalogImportCarseatsUrl = url(`/adminclub/product-catalog/${PRODUCT_SEGMENT}/import/`, {productSegment: 'carseats'});
export const adminclubStrollersPatterns = `${adminclubUrl}strollers-patterns/`;
export const adminclubStrollersInsertPatterns = `${adminclubUrl}strollers-patterns/insert/`;
export const adminclubStrollerEditPattern = `${adminclubUrl}strollers-patterns/edit/${PATTERN_ID}/`;

export const adminclubAIPromptEdit = `/adminclub/prompt/edit/${PROMPT_ID}/`;
