import {
    ARTICLE_COMMENTABILITY,
    ARTICLE_TEXT,
    ARTICLE_TITLE,
    ARTICLE_VISIBILITY,
    AUTHENTICATED_ONLY_POST,
    COMMENTABLE_BY_AUTHENTICATED,
    COMMENTABLE_BY_NOBODY,
    HIDE_REPLIES,
    PHOTOBLOG_ARTICLE_FORM_AUTHOR,
    PHOTOBLOG_ARTICLE_SCRIPTS,
    PUBLIC_POST,
    PUBLISH_TIME_LEAVE_EMPTY_FOR_NOW,
    SHOW_IN_CONTESTS,
    YOU_DID_NOT_ENTER_ANY_ARTICLE_TEXT,
    YOU_DID_NOT_ENTER_ANY_ARTICLE_TITLE,
} from 'mk/autogenerated/translations/ArticleCreateEditForm.fd36411ab81a8146438bdf2ec8b8d671'
import { canManageBlogs } from 'mk/bazaar/common/userUtils';
import { canCreateBlogContests } from 'mk2/apps/blogs/utils';
import { strollersPrefix } from 'mk2/apps/strollers/urls';
import WikiFormatHelp from 'mk2/apps/wiki/components/WikiFormatHelp';
import { CheckboxField } from 'mk2/components/forms/CheckboxField';
import { InputField } from 'mk2/components/forms/InputField';
import { SelectField } from 'mk2/components/forms/SelectField';
import { SmartTextareaEditorType, SmartTextareaField } from 'mk2/components/forms/SmartTextareaField';
import { TextareaField } from 'mk2/components/forms/TextareaField';
import { Form } from 'mk2/components/Form';
import { FormPageType } from 'mk2/constants/enums';
import { PhotosUploadConfig } from 'mk2/containers/PhotosUpload/PhotosUpload';
import { ComposeToolbar } from 'mk2/containers/Toolbar/Toolbar';
import { mkReduxForm, MKReduxFormInjectedProps } from 'mk2/decorators/mkReduxForm';
import { PhotoEntityOrSuccessfulPhotoUpload } from 'mk2/helpers/form.reducers';
import { AST, PostCommentability, PostVisibility, UserEntity } from 'mk2/schemas';
import React from 'react';
import { FormErrors } from 'redux-form';
import styles from './ArticleCreateEditForm.mscss';

export const ARTICLE_CREATE_EDIT_FORM_NAME = 'ARTICLE_CREATE_EDIT_FORM';

export interface ArticleFormData {
    title: string;
    body: string; // format HTML / MD depends on editorType
    bodyAST: AST;
    editorType: SmartTextareaEditorType;
    author: string;
    scripts: string;
    publishTime: string;
    visibility: PostVisibility;
    commentability: PostCommentability;
    photos: PhotoEntityOrSuccessfulPhotoUpload[];
}

interface OwnProps {
    isEdit: boolean;
    isMobile: boolean;
    initialValues: ArticleFormData;
    currentValues: ArticleFormData;
    requestUser: UserEntity;
    requestUserPermissions: string[];
    allowEditorTypeChange: boolean;

    onSave(values: ArticleFormData);
    onEditorTypeChange(editorType: SmartTextareaEditorType);
    onPrepareUploadPhotosCustom?(editor: any, formName: string, config: PhotosUploadConfig, files: File[], processPhotoUploadOnFormPage: FormPageType, accessKey?: string);
}

type Props = OwnProps;

const photosConfig = {
    photoType: 'article',
    multiple: true,
    showPhotoCode: false,
    previewPhotoSize: 's560x560',
    disableRotate: true,
};

export class ArticleCreateEditFormComponent extends React.Component<Props & MKReduxFormInjectedProps<ArticleFormData, Props>> {

    public static validate(values: ArticleFormData): FormErrors<ArticleFormData> {
        const errors: FormErrors<ArticleFormData> = {};
        if (!values.title) {
            errors.title = YOU_DID_NOT_ENTER_ANY_ARTICLE_TITLE;
        }

        if (!values.body) {
            errors.body = YOU_DID_NOT_ENTER_ANY_ARTICLE_TEXT;
        }
        return errors;
    }

    private toolbarsHandleOpenPhotosFn = null;
    private smartTextareaFieldEditor = null;

    public render() {
        const {
            isEdit, isMobile, handleSubmit, currentValues, requestUser, requestUserPermissions,
            onEditorTypeChange, initialValues, allowEditorTypeChange,
        } = this.props;

        const editorType = currentValues?.editorType ?? initialValues?.editorType ?? SmartTextareaEditorType.RICHTEXT;
        const isBlogsAdmin = canManageBlogs(requestUserPermissions);
        const canCreateContests = canCreateBlogContests(requestUser);

        return (
            <Form className={styles.ArticleCreateEditForm} onSubmit={handleSubmit}>
                <TextareaField
                    name="title"
                    label={ARTICLE_TITLE}
                    maxLength={100}
                    hasLargeFont
                    rows={1}
                    autoFocus
                />

                <SmartTextareaField
                    name="body"
                    label={`${ARTICLE_TEXT}`}
                    editorTypeOnInit={editorType}
                    allowEditorTypeChange={allowEditorTypeChange}
                    onEditorTypeChange={onEditorTypeChange}
                    onInsertImage={this.handleOnInsertImage}
                />

                {isEdit && isBlogsAdmin && (
                    <InputField
                        name="author"
                        type="text"
                        label={PHOTOBLOG_ARTICLE_FORM_AUTHOR}
                        maxLength={100}
                    />
                )}

                {isBlogsAdmin && (
                    <TextareaField
                        name="scripts"
                        label={PHOTOBLOG_ARTICLE_SCRIPTS}
                        rows={isMobile ? 4 : 5}
                        fillRemainingSpace
                    />
                )}

                <SelectField
                    name="visibility"
                    options={
                        [
                            {label: PUBLIC_POST, value: PostVisibility.Public},
                            {label: AUTHENTICATED_ONLY_POST, value: PostVisibility.Authenticated},
                        ]
                    }
                    label={ARTICLE_VISIBILITY}
                />

                <SelectField
                    name="commentability"
                    options={
                        [
                            {label: COMMENTABLE_BY_NOBODY, value: PostCommentability.CommentableByNobody},
                            {label: COMMENTABLE_BY_AUTHENTICATED, value: PostCommentability.CommentableByAuthenticated},
                        ]
                    }
                    label={ARTICLE_COMMENTABILITY}
                    bottomBorder={isBlogsAdmin}
                />

                {isBlogsAdmin && (
                    <InputField
                        key={'key0'}
                        name="publishTime"
                        type="datetime-local"
                        label={PUBLISH_TIME_LEAVE_EMPTY_FOR_NOW}
                    />
                )}

                {canCreateContests && !isEdit && [
                    (
                        <CheckboxField
                            key="isContest"
                            name="isContest"
                            label={SHOW_IN_CONTESTS}
                            isAdminUI
                        />
                    ),
                    (
                        <CheckboxField
                            key="hideReplies"
                            name="hideReplies"
                            label={HIDE_REPLIES}
                            isAdminUI
                        />
                    ),
                ]}

                {editorType === SmartTextareaEditorType.MARKDOWN && (
                    <h3 className={styles.ArticleCreateEditForm__formatHelpHeading}>HELP:</h3>
                )}

                {editorType === SmartTextareaEditorType.MARKDOWN && (
                    <WikiFormatHelp
                        domain={(
                            requestUser.username === strollersPrefix ||
                            currentValues?.author === strollersPrefix
                        ) ? 'strollers-blog-article' : undefined}
                    />
                )}

                <ComposeToolbar
                    formName={ARTICLE_CREATE_EDIT_FORM_NAME}
                    photos={{ ...photosConfig, disableRotate: editorType === SmartTextareaEditorType.RICHTEXT}}
                    stickers={false}
                    isInBlogs
                    passiveMode
                    handleOpenFileExplorerFunctionProcessor={this.processToolbarsOpenFileExplorerFunction}
                    onPrepareUploadPhotosCustom={this.handleOnPrepareUploadPhotosCustom}
                />
            </Form>
        );
    }

    private handleOnInsertImage = (editor: any) => {
        this.smartTextareaFieldEditor = editor;
        this.toolbarsHandleOpenPhotosFn();
    };

    private handleOnPrepareUploadPhotosCustom = (formName: string, config: PhotosUploadConfig, files: File[], processPhotoUploadOnFormPage: FormPageType, accessKey?: string) => {
        const { onPrepareUploadPhotosCustom } = this.props;
        onPrepareUploadPhotosCustom(this.smartTextareaFieldEditor, formName, photosConfig, files, processPhotoUploadOnFormPage, accessKey);
    };

    private processToolbarsOpenFileExplorerFunction = (handleOpenPhotosFn: any) => {
        this.toolbarsHandleOpenPhotosFn = handleOpenPhotosFn;
    };
}

export const ArticleCreateEditForm = mkReduxForm<ArticleFormData, Props>({
    formName: ARTICLE_CREATE_EDIT_FORM_NAME,
    photosConfig,
    onValidate: (values) => ArticleCreateEditFormComponent.validate(values),
    onSave: (values, props) => props.onSave(values),
})(ArticleCreateEditFormComponent);
