import { faSmile } from '@fortawesome/pro-regular-svg-icons';
import { faCamera } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import assetUrl from 'mk/common/assetUrl';
import { decidePreviewPhotoVersion } from 'mk/photo/photoUrl';
import { Emojis } from 'mk2/components/forms/Emojis';
import { PhotosFieldNew } from 'mk2/components/forms/PhotosFieldNew';
import { Btn,  BtnCountType, BtnLayout } from 'mk2/components/Btn';
import { Img } from 'mk2/components/Img';
import { ImgCropped, ImgCropMode } from 'mk2/components/ImgCropped';
import { PortalLoadable } from 'mk2/components/PortalLoadable';
import { FormPageType } from 'mk2/constants/enums';
import { default as PhotosUpload, PhotosUploadConfig, PhotoUploadType } from 'mk2/containers/PhotosUpload/PhotosUpload';
import {
    toolbarCloseAll,
    toolbarOpenEmojis,
    toolbarOpenPhotos,
    toolbarOpenStickers,
    toolbarPreparePhotosUploadTrigger,
} from 'mk2/containers/Toolbar/Toolbar.actions';
import { ToolbarAction } from 'mk2/containers/Toolbar/Toolbar.enums';
import styles from 'mk2/containers/Toolbar/Toolbar.mscss';
import { mkReduxFormOpenPage } from 'mk2/helpers/form.actions';
import {
    FailedPhotoUpload,
    PendingPhotoUpload,
    PhotoUploadStatus,
    SuccessfulPhotoUpload,
} from 'mk2/helpers/form.reducers';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import Loadable from 'mk2/helpers/Loadable';
import { getRequestDeviceIsIOS, getRequestDeviceMobile, getToolbarState, AppState } from 'mk2/reducers';
import { PhotoEntity, PhotoSchema } from 'mk2/schemas';
import { getDenormalizedEntity } from 'mk2/selectors';
import React from 'react';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';

const StickerField = Loadable({
    loader: () => import('mk2/components/forms/StickerField' /* webpackChunkName: "components.forms.StickerField" */).then((mod) => mod.StickerField),
    modules: ['mk2/components/forms/StickerField'],
    webpack: () => [ require.resolveWeak('mk2/components/forms/StickerField') ],
});

export enum ToolbarTheme {
    White,
    Gray,
    Blue,
    Black,
}

interface OwnProps {
    className?: string;
    // meno formu, pre ktory je toolbar pouzity
    formName: string;

    // button zobrazeny na pravo v riadku s ikonami fotaku a stickera
    rightAction?: React.ReactNode;
    // input zobrazeny nad riadkom s ikonami fotaku a stickera
    textArea?: React.ReactNode;

    // Additional buttons send from parent
    additionalActions?: React.ReactNode;

    // emojis button len na desktope. na mobile nie je vobec (tam je nativna klavesnica)
    emojis?: {
        fields: string[]; // List of input fields where we can insert emoji
        defaultField: string; // Default field where we insert emoji after click when no field was focused
    };

    // button na upload fotiek. Ak null, toolbar button na upload fotiek nema
    photos?: PhotosUploadConfig; // TODO: rename var to photosUploadConfig
    stickers?: boolean;

    // TODO: create enum instead of more and more boolean flags
    isInBlogs?: boolean;
    isInForum?: boolean;
    isInGroups?: boolean;
    isInAdminclub?: boolean;
    isInCounsellings?: boolean;

    isFixed?: boolean;
    isGray?: boolean;
    isBlack?: boolean;
    isBlue?: boolean;
    passiveMode?: boolean; // if true, upload next photo button is hidden and no file selection popup will display

    // counselling reply - unauthenticated upload (e.g. accessKey='counselling_reply:slug:pE96mf3AUfB4mLJv')
    accessKey?: string;

    handleOpenFileExplorerFunctionProcessor?(handleOpenPhotosFn: any);
    onPrepareUploadPhotosCustom?(formName: string, config: PhotosUploadConfig, files: File[], processPhotoUploadOnFormPage: FormPageType, accessKey?: string);
}

interface StateProps {
    opened: ToolbarAction;
    isIOS: boolean;
    isMobile: boolean;

    currentlySelectedSticker: number;

    currentPhotos: Array<PendingPhotoUpload | SuccessfulPhotoUpload | FailedPhotoUpload>;
    currentPhotosFirstPhoto?: PhotoEntity;  // photo from first upload, if we have it
}

interface DispatchProps {
    onClose(formName: string);
    onOpenEmojisInPlace(formName: string);
    onOpenPhotos(formName: string);
    onPrepareUploadPhotos(formName: string, config: PhotosUploadConfig, files: File[], processPhotoUploadOnFormPage: FormPageType, accessKey?: string);
    onOpenStickersInPlace(formName: string);

    onInsertEmoji(formName: string, fieldName: string, fieldValue: string);

    onOpenFormPage(formName, formPage);
}

type ToolbarProps = OwnProps & StateProps & DispatchProps;

class Toolbar extends React.PureComponent<ToolbarProps> {

    public static defaultProps: Partial<OwnProps> = {
        stickers: true,
        isInAdminclub: false,
    };

    private photosFieldRef: PhotosFieldNew;
    private toolbarRef = React.createRef<HTMLDivElement>();
    private lastFocusedInput: HTMLInputElement | HTMLTextAreaElement = null;

    public componentDidMount() {
        const { handleOpenFileExplorerFunctionProcessor } = this.props;

        window.addEventListener('focusin', this.handleOnFocusIn);

        if (handleOpenFileExplorerFunctionProcessor) {
            handleOpenFileExplorerFunctionProcessor(this.handleOpenFileExplorer);
        }
    }

    public componentWillUnmount() {
        const { formName, onClose } = this.props;

        // Close opened in the state
        onClose(formName);

        window.removeEventListener('focusin', this.handleOnFocusIn);
    }

    public render() {
        const {
            formName, className, emojis, photos, stickers, opened, textArea, isBlue, isInCounsellings,
            isFixed, isGray, isBlack, isIOS, isMobile, rightAction, isInBlogs, isInForum, isInGroups, isInAdminclub,
            currentlySelectedSticker, currentPhotos, currentPhotosFirstPhoto, passiveMode,
            additionalActions,
        } = this.props;

        const hasEmojis = !!emojis && !isMobile;  // emojis nie su na mobile (tam ich ma klavesnica)
        const hasPhotos = !!photos;
        const hasStickers = stickers;

        const fixed = (isFixed !== undefined) ? isFixed : !isInBlogs && isMobile && !isIOS;
        const isMobileOrIOS =  isMobile || isIOS;
        const numConfirmedSuccessfulUploads = currentPhotos ? currentPhotos.filter((u) => (u.status === PhotoUploadStatus.SUCCESS)).length : 0;

        const showEmojisModal = hasEmojis && opened === ToolbarAction.EMOJIS;
        const showPhotosModal = hasPhotos && opened === ToolbarAction.PHOTOS && (!isMobileOrIOS || isInBlogs || isInGroups || isInCounsellings);
        const showStickersModal = hasStickers && opened === ToolbarAction.STICKERS;
        const showPhotoCode = hasPhotos && photos.showPhotoCode;
        const disableRotate = hasPhotos && photos.disableRotate;
        const disablePreview = photos?.disablePreview || false;
        const previewPhotoSize = hasPhotos && decidePreviewPhotoVersion(photos.previewPhotoSize, currentPhotosFirstPhoto);
        const miniPreviewPhotoSize = previewPhotoSize;
        const handleOpenFileExplorer = photos && photos.multiple ? this.handleOpenFileExplorer : null;

        return (
            <div
                className={cx(styles.Toolbar, className, {
                    [styles['Toolbar--inBlogs']]: isInBlogs,
                    [styles['Toolbar--inForum']]: isInForum,
                    [styles['Toolbar--inAdminclub']]: isInAdminclub,
                    [styles['Toolbar--inGroups']]: isInGroups,
                    [styles['Toolbar--opened']]: opened !== ToolbarAction.NONE,
                    [styles['Toolbar--fixed']]: fixed,
                    [styles['Toolbar--blueTheme']]: !isGray && !isBlack && ((isMobileOrIOS && isInForum) || isBlue || isInGroups),
                    [styles['Toolbar--grayTheme']]: isGray,
                    [styles['Toolbar--blackTheme']]: isBlack,
                })}
                ref={this.toolbarRef}
            >
                {!isInBlogs && textArea && (
                    <div
                        className={cx({
                            [styles['Toolbar__textAreaWrapper--inForum']]: isInForum && !isBlue && !isMobileOrIOS,
                        })}
                    >
                        {textArea}
                    </div>
                )}
                <div className={styles.Toolbar__actions}>
                    <div className={styles.Toolbar__actions__left}>
                    {hasEmojis && !isInBlogs && (
                        <Btn
                            className={styles.Toolbar__actions__action}
                            layout={BtnLayout.Icon}
                            icon={faSmile}
                            onClick={this.handleOpenEmojis}
                        />
                    )}
                    {hasPhotos && !currentlySelectedSticker && [
                        currentPhotosFirstPhoto ? (
                            <Btn
                                key="btn-img"
                                className={styles.Toolbar__actions_action}
                                layout={BtnLayout.Icon}
                                img={
                                    <ImgCropped
                                        photo={currentPhotosFirstPhoto}
                                        photoVersion={miniPreviewPhotoSize}
                                        height={20}
                                        width={20}
                                        mode={ImgCropMode.Cover}
                                    />
                                }
                                count={numConfirmedSuccessfulUploads}
                                countType={BtnCountType.Circle}
                                onClick={this.handleOpenPhotos}
                            />
                        ) : (
                            <Btn
                                key="btn-icon"
                                className={cx(
                                    styles.Toolbar__actions__action,
                                    styles.Toolbar__actions__action__photos,
                                    (!hasStickers || currentPhotos.length > 0) && styles.Toolbar__actions__action__no_stickers,
                                )}
                                layout={BtnLayout.Icon}
                                icon={faCamera}
                                onClick={this.handleOpenPhotos}
                            />
                        ),
                    ]}
                    {hasStickers && (currentPhotos.length === 0) && (
                        ((isMobileOrIOS && isInForum) || isInGroups) && !currentlySelectedSticker ? (
                            <Btn
                                className={cx(
                                    styles.Toolbar__actions__action,
                                    styles.Toolbar__actions__action__stickers,
                                )}
                                layout={BtnLayout.Icon}
                                img={
                                    <Img
                                        src={assetUrl + 'img/icons/sticker_blue_placeholder.png'}
                                        width={25}
                                        height={25}
                                    />
                                }
                                onClick={this.handleOpenStickers}
                            />
                        ) : (
                            <Btn
                                className={cx(
                                    styles.Toolbar__actions__action,
                                    styles.Toolbar__actions__action__stickers,
                                    (!hasPhotos || currentlySelectedSticker) && styles.Toolbar__actions__action__no_photos,
                                    (isMobileOrIOS && currentlySelectedSticker) && styles.Toolbar__actions__selectedSticker,
                                )}
                                layout={BtnLayout.Icon}
                                icon={'sticker-' + (currentlySelectedSticker || 0)}
                                onClick={this.handleOpenStickers}
                            />
                        )
                    )}
                    {hasEmojis && isInBlogs && (
                        <Btn
                            className={cx(styles.Toolbar__actions__action, styles.Toolbar__actions__action__emojis)}
                            layout={BtnLayout.Icon}
                            icon={faSmile}
                            onClick={this.handleOpenEmojis}
                        />
                    )}

                    {additionalActions}

                    </div>
                    {rightAction && (!isInBlogs || (isInBlogs && !isMobile))  && (
                        <div className={styles.Toolbar__actions__right}>
                            {rightAction}
                        </div>
                    )}
                </div>
                {hasPhotos && !currentlySelectedSticker && [
                    // this has to be always rendered, so that <input type="file"> is in dom
                    // and we can call it's click() to show file open dialog
                    <PhotosFieldNew
                        key="fileinput"
                        name="photos"
                        config={photos}
                        ref={this.handlePhotosFileInputFieldRef}
                        onUploadFiles={this.handleUploadFiles}
                    />,
                ]}

                {(showEmojisModal || showPhotosModal || showStickersModal) && (
                    <div className={styles.Toolbar__modal}>
                        {/* Ignore height for desktop - show everything */}
                        {showEmojisModal && (
                            <Emojis
                                onSelect={this.handleEmojiInsert}
                            />
                        )}
                        {showPhotosModal && (
                            <PhotosUpload
                                formName={formName}
                                photoUploadType={PhotoUploadType.REGULAR_PHOTOS}
                                onClickUploadNextPhoto={passiveMode ? null : handleOpenFileExplorer}
                                previewPhotoSize={previewPhotoSize}
                                showPhotoCode={showPhotoCode}
                                disableRotate={disableRotate}
                                disablePreview={disablePreview}
                            />
                        )}
                        {showStickersModal && (
                            <StickerField
                                name="sticker"
                                onSelect={this.handleStickerSelected}
                            />
                        )}
                    </div>
                )}

                {rightAction && isInBlogs && isMobile && (
                    <div className={styles.Toolbar__actions__right}>
                        {rightAction}
                    </div>
                )}
            </div>
        );
    }

    private handleEmojiInsert = (shortcode: string) => {
        const { formName, emojis, onInsertEmoji } = this.props;

        let targetInput: HTMLInputElement | HTMLTextAreaElement = this.lastFocusedInput;
        if (!targetInput && this.toolbarRef.current && emojis && emojis.defaultField) {
            // take default input in form
            targetInput = this.toolbarRef.current
                .closest('form')
                .querySelector(`input[name=${emojis.defaultField}], textarea[name=${emojis.defaultField}]`);

            if (!targetInput) {
                return;
            }
        }

        const value = targetInput.value;
        let selectionStart = targetInput.selectionStart;
        let selectionEnd = targetInput.selectionEnd;

        const head = value.substring(0, selectionStart);
        const tail = value.substring(selectionEnd);

        const newValue = head + shortcode + tail;
        onInsertEmoji(formName, targetInput.name, newValue);

        // Move cursor
        selectionEnd = selectionStart = selectionStart + shortcode.length;
        targetInput.focus(); // setting focus also closes the emoji tab
        requestAnimationFrame(() => {
            targetInput.setSelectionRange(selectionStart, selectionEnd);
        });
    };

    private handleStickerSelected = (stickerId) => {
        const { formName, onClose } = this.props;

        // always close toolbar (also when current sticker is unselected)
        onClose(formName);
    };

    private handlePhotosFileInputFieldRef = (ref: PhotosFieldNew) => {
        this.photosFieldRef = ref;
    };

    private handleOpenFileExplorer = () => {
        // no photos yet, show file open dialog
        this.photosFieldRef.openFileExplorer();
    };

    private handleUploadFiles = (config: PhotosUploadConfig, files: File[]) => {
        const {
            isMobile, isIOS, isInBlogs, isInGroups, isInCounsellings,
            formName, onPrepareUploadPhotos, accessKey,
            onPrepareUploadPhotosCustom } = this.props;
        const processPhotoUploadOnFormPage = (!(isMobile || isIOS) || isInBlogs || isInGroups || isInCounsellings)
            ? FormPageType.Main
            : FormPageType.Photos;
        if (onPrepareUploadPhotosCustom) {
            onPrepareUploadPhotosCustom(formName, config, files, processPhotoUploadOnFormPage, accessKey);
        } else {
            onPrepareUploadPhotos(formName, config, files, processPhotoUploadOnFormPage, accessKey);
        }
        this.handleOpenPhotos(false, true);
    };

    private handleOpenPhotos = (openFileDialog: boolean = true, justOpenNoToggle: boolean = false) => {
        const {
            isMobile, isInBlogs, isInGroups, isInCounsellings,
            onOpenFormPage, onOpenPhotos,
            opened, formName, currentPhotos, passiveMode,
        } = this.props;

        if (openFileDialog && (!currentPhotos || currentPhotos.length === 0)) {
            if (!passiveMode) {
                this.handleOpenFileExplorer();
            }
        } else {
            if (isMobile && !isInBlogs && !isInGroups && !isInCounsellings) {
                onOpenFormPage(formName, FormPageType.Photos);
            } else {
                if (!justOpenNoToggle || (justOpenNoToggle && opened !== ToolbarAction.PHOTOS)) {
                    onOpenPhotos(formName);
                }
            }
        }
    };

    private handleOpenEmojis = () => {
        const { formName, isInBlogs, isMobile, onOpenEmojisInPlace } = this.props;

        if (isMobile && !isInBlogs) {
            // pass, on mobile there are no emojis in the Toolbar except blogs
        } else {
            onOpenEmojisInPlace(formName);
        }
    };

    private handleOpenStickers = () => {
        const { formName, onOpenFormPage, isInBlogs, isInGroups, isInCounsellings, isMobile, onOpenStickersInPlace } = this.props;

        if (isMobile && !isInBlogs && !isInGroups && !isInCounsellings) {
            // open stickers in new form page
            onOpenFormPage(formName, FormPageType.Stickers);
        } else {
            // open stickers at the bottom of the form
            onOpenStickersInPlace(formName);
        }
    };

    private isElementFromToolbarForm(el1: HTMLElement): boolean {
        // toto je trochu dementne riesenie :D, ale nenapada ma krajsie
        // Na stranke moze byt viacero Toolbarov (napr. v group feede) a my chceme
        // dispatchnut akciu len pre ten jeden toolbar co ziskal focus. Lenze textarea,
        // ktora dostane focus moze ale aj nemusi byt DOM-descendat divu pre toolbar.
        // Obaja (toolbar aj textarea) by ale mali byt v tom istom form elemente.
        const form1: HTMLElement = el1 ? el1.closest('form') : null;
        const form2: HTMLElement = this.toolbarRef.current ? this.toolbarRef.current.closest('form') : null;

        return !!form1 && !!form2 && form1 === form2;
    }

    private handleOnFocusIn = (ev: FocusEvent) => {
        const { formName, onClose, opened, emojis } = this.props;
        const focusedEl: HTMLElement = ev.target as HTMLElement;

        if (this.isElementFromToolbarForm(focusedEl)) {
            if (['input', 'textarea'].indexOf(focusedEl.tagName.toLowerCase()) >= 0) {
                const focusedInput = focusedEl as HTMLInputElement | HTMLTextAreaElement;
                if (emojis && emojis.fields && emojis.fields.indexOf(focusedInput.name) >= 0) {
                    this.lastFocusedInput = focusedInput;
                }
            }

            if (opened !== ToolbarAction.NONE) {
                // textarra inside toolbar's form got focus
                onClose(formName);
            }
        }
    };
}

const container = () => document.body;

class ToolbarWrapper extends React.Component<ToolbarProps> {
    public render() {
        const { isMobile, isIOS, isInBlogs, isInGroups, isInCounsellings } = this.props;
        return ((!isMobile || isIOS || isInBlogs || isInGroups || isInCounsellings) ? (
            <Toolbar {...this.props} />
        ) : (
            <PortalLoadable container={container}>
                <Toolbar {...this.props} />
            </PortalLoadable>
        ));
    }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
    const { formName } = ownProps;

    const selector = formValueSelector(formName);
    const toolbarState = getToolbarState(state, formName);

    const currentPhotos = selector(state, 'photos') as any[] || [];
    const currentPhotosFirstPhoto: PhotoEntity =
        (currentPhotos && currentPhotos.length > 0 && currentPhotos[0].status === PhotoUploadStatus.SUCCESS)
            ? getDenormalizedEntity<PhotoEntity>(state, PhotoSchema, (currentPhotos[0] as SuccessfulPhotoUpload).photoId)
            : null;

    return {
        opened: toolbarState.opened,
        isIOS: getRequestDeviceIsIOS(state),
        isMobile: getRequestDeviceMobile(state),
        currentlySelectedSticker: selector(state, 'sticker') as number,
        currentPhotos,
        currentPhotosFirstPhoto,
    };
}

const mapDispatchToProps: MapDispatchToPropsObject<DispatchProps> = {
    onClose: toolbarCloseAll,
    onOpenEmojisInPlace: toolbarOpenEmojis,
    onOpenPhotos: toolbarOpenPhotos,
    onPrepareUploadPhotos: toolbarPreparePhotosUploadTrigger,
    onOpenStickersInPlace: toolbarOpenStickers,
    onOpenFormPage: mkReduxFormOpenPage,

    onInsertEmoji: change,
};

export const ComposeToolbar = connect(mapStateToProps, mapDispatchToProps)(ToolbarWrapper);
