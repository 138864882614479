// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const ARTICLE_COMMENTABILITY = "Kto m\u00f4\u017ee prid\u00e1va\u0165 koment\u00e1re?";
export const ARTICLE_TEXT = "Znenie \u010dl\u00e1nku";
export const ARTICLE_TITLE = "Nadpis \u010dl\u00e1nku";
export const ARTICLE_VISIBILITY = "Pre koho je vidite\u013en\u00fd\u00a0tento \u010dl\u00e1nok?";
export const AUTHENTICATED_ONLY_POST = "Len pre registrovan\u00fdch";
export const COMMENTABLE_BY_AUTHENTICATED = "Registrovan\u00ed pou\u017e\u00edvatelia";
export const COMMENTABLE_BY_NOBODY = "Nikto nem\u00f4\u017ee";
export const HIDE_REPLIES = "Nezobrazuj koment\u00e1re";
export const PHOTOBLOG_ARTICLE_FORM_AUTHOR = "Uverejni\u0165 \u010dl\u00e1nok ako pou\u017e\u00edvate\u013e";
export const PHOTOBLOG_ARTICLE_SCRIPTS = "Marketingov\u00e9 a in\u00e9 k\u00f3dy (script / pixel / tag)";
export const PUBLIC_POST = "Pre v\u0161etk\u00fdch (aj Google)";
export const PUBLISH_TIME_LEAVE_EMPTY_FOR_NOW = "\u010cas uverejnenia - (vypl\u0148 len ak chce\u0161 bud\u00faci d\u00e1tum a ulo\u017e ako rozp\u00edsan\u00fd)";
export const SHOW_IN_CONTESTS = "Zobrazuj v \"S\u00fa\u0165a\u017ee\"";
export const YOU_DID_NOT_ENTER_ANY_ARTICLE_TEXT = "Nezadala si znenie \u010dl\u00e1nku.";
export const YOU_DID_NOT_ENTER_ANY_ARTICLE_TITLE = "Nezadala si titulok \u010dl\u00e1nku.";
